import QR from "qr-encode";
import url from "../_URL";

function certif(pdf,data,mensaje) {
    var logo = "images/logo.jpg";
    var dataURI = QR(url.val+data.id, { type: 6, size: 5, level: "Q" ,
    image: "https://upload.wikimedia.org/wikipedia/commons/5/51/Facebook_f_logo_%282019%29.svg",});
    var img = new Image();
    img.src = dataURI;
    var qr = img.src;

    pdf.addImage(qr, 'JPEG', 10, 10, 40, 40);
    pdf.setFontSize(9);
    pdf.setFont("italic","bold");
    pdf.text("Scan QR code for VALIDITY", 10, 8);

    pdf.addImage(logo, 'JPEG', 65, 10, 72, 20);
    pdf.setFontSize(12);
    pdf.setFont("italic", "bold");
    pdf.text("SaBeé Insurance", 65, 35);
    pdf.setFontSize(8);
    pdf.setFont("italic","normal");
    pdf.text("Do it Secure, Do it SaBee", 65, 38);
    pdf.text("World Trade Center, Piscadera Bay Z/N, Willemstad – Curaçao", 65, 41);
    pdf.text("Phone +59996761002 - +59996905811", 65, 44);
    pdf.text("Email: sales@sabeeinsurance.com", 65, 47);
    pdf.text(100, 139, 'SaBee Insurance. – Registration Number 102716', 'center');
    pdf.setFont("italic","normal");
    pdf.text(100, 142, 'Website: www.sabeeinsurance.com', 'center');

    pdf.setFontSize(12);
    pdf.setFont("italic","bold");
    pdf.text(100, 56, "POLICY: "+data.codigo, 'center');

    pdf.setFontSize(10);
    pdf.rect(10, 60, 190, 75); 

    pdf.setFont("italic","normal");
    pdf.text("Doc Id:", 15, 70);
    pdf.rect(33, 65, 35, 7); 
    pdf.text("Policy Holder:", 70, 70);
    pdf.rect(95, 65, 100, 7); 
    pdf.text("Client Nr:", 15, 80);
    pdf.rect(33, 75, 35, 7); 
    pdf.text("Policy:", 70, 80);
    pdf.rect(95, 75, 100, 7); 
    pdf.text("Duration:", 15, 90);
    pdf.rect(33, 85, 35, 7); 
    pdf.text("Period:", 70, 90);
    pdf.rect(95, 85, 100, 7); 

    pdf.setFontSize(8);
    pdf.text(100, 130, "Client has accepted policy conditions.", 'center');
    pdf.text(100, 133, "See terms & conditions", 'center');
    pdf.setFontSize(10);
    pdf.text(mensaje, 70, 130);
    pdf.setFontSize(8);

    pdf.setFont("italic","bold");
    pdf.text(data.incendio.code, 35, 70);            
    pdf.text(data.incendio.nombre, 100, 70);
    pdf.text('# '+data.usuario.toString().padStart(7,'0'), 35, 80);
    pdf.text(data.incendio.tipopoliza_name, 100, 80);
    pdf.text(data.duracion_name, 35, 90);
    pdf.text(data.activacion+' - '+data.vence, 100, 90);
    pdf.setFontSize(14);

    pdf.setFontSize(10);
    pdf.setFont("italic","normal");
    pdf.text("Riskk address:", 15, 100);
    pdf.rect(43, 95, 152, 7); 
    pdf.text("Beneficiary:", 15, 110);
    pdf.rect(43, 105, 60, 7); 
    pdf.text("Deductible :", 105, 110);
    pdf.rect(125, 105, 70, 7); 
    if(data.incendio.direccion){
        pdf.text(data.incendio.direccion, 50, 100);  
    }                     
    if(data.incendio.beneficiario){
        pdf.text(data.incendio.beneficiario, 50, 110);     
    }   
    pdf.text(data.incendio.deductible, 130, 110);        
    pdf.text("Coverage subjected to policy conditions and inspections if requested.", 15, 120);
}
export default certif;