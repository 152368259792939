import { createRouter, createWebHashHistory } from 'vue-router';

const routes = [
    {
        path: '/',
        name: 'Home',
        component: () => import(/* webpackChunkName: "Home" */ './pages/Home.vue')
    },
    {
        path: "/query/:cod",
        name: 'Query',
        component: () => import(/* webpackChunkName: "Query" */ './pages/Query.vue')
    },
    {
        path: '/company',
        name: 'Company',
        component: () => import(/* webpackChunkName: "Company" */ './pages/Company.vue')
    },
    {
        path: '/services',
        name: 'Services',
        component: () => import(/* webpackChunkName: "Services" */ './pages/Services.vue')
    },
    {
        path: '/contact',
        name: 'Contact',
        component: () => import(/* webpackChunkName: "Contact" */ './pages/Contact.vue')
    },
    {
        path: '/register',
        name: 'Register',
        component: () => import(/* webpackChunkName: "Register" */ './pages/Register.vue')
    },
    {
        path: '/simulator',
        name: 'Simulator',
        component: () => import(/* webpackChunkName: "Simulator" */ './pages/Simulator.vue')
    },

    {
        path: '/dashboard',
        name: 'Dashboard',
        component: () => import/* webpackChunkName: "Dashboard" */ ('./pages/Dashboard.vue'),
        meta: {
            authRequired: true
        }
    },
    {
        path: '/check',
        name: 'Check',
        component: () => import(/* webpackChunkName: "Check" */ './pages/Check.vue'),
        meta: {
            authRequired: true
        }
    },
    {
        path: '/customers',
        name: 'Customers',
        component: () => import(/* webpackChunkName: "Customers" */ './mod_conf/Customers.vue'),
        meta: {
            authRequired: true
        }
    },
    {
        path: '/messages',
        name: 'Messages',
        component: () => import(/* webpackChunkName: "Customers" */ './mod_conf/Messages.vue'),
        meta: {
            authRequired: true
        }
    },
    {
        path: '/broker',
        name: 'Broker',
        component: () => import(/* webpackChunkName: "Broker" */ './mod_conf/Broker.vue'),
        meta: {
            authRequired: true
        }
    },
    {
        path: '/location',
        name: 'Location',
        component: () => import(/* webpackChunkName: "Location" */ './mod_conf/Location.vue'),
        meta: {
            authRequired: true
        }
    },
    {
        path: '/office',
        name: 'Office',
        component: () => import(/* webpackChunkName: "Office" */ './mod_conf/Office.vue'),
        meta: {
            authRequired: true
        }
    },
    {
        path: '/coin',
        name: 'Moneda',
        component: () => import(/* webpackChunkName: "Moneda" */ './mod_conf/Moneda.vue'),
        meta: {
            authRequired: true
        }
    },
    {
        path: '/users',
        name: 'Usuarios',
        component: () => import(/* webpackChunkName: "Users" */ './mod_conf/Users.vue'),
        meta: {
            authRequired: true
        }
    },
    {
        path: '/myquotation',
        name: 'MyQuotation',
        component: () => import(/* webpackChunkName: "MyQuotation" */ './mod_quotation/MyQuotation.vue'),
        meta: {
            authRequired: true
        }
    },
    {
        path: '/credit',
        name: 'Credit',
        component: () => import(/* webpackChunkName: "MyQuotation" */ './mod_payment/Credit.vue'),
        meta: {
            authRequired: true
        }
    },
    {
        path: '/segquotation',
        name: 'SegQuotation',
        component: () => import(/* webpackChunkName: "SegQuotation" */ './mod_quotation/SegQuotation.vue'),
        meta: {
            authRequired: true
        }
    },
    {
        path: '/quotation',
        name: 'Quotation',
        component: () => import(/* webpackChunkName: "Quotation" */ './mod_quotation/Quotation.vue'),
        meta: {
            authRequired: true
        }
    },
    {
        path: '/quotations',
        name: 'Quotations',
        component: () => import(/* webpackChunkName: "Quotations" */ './mod_quotation/Quotations.vue'),
        meta: {
            authRequired: true
        }
    },
    {
        path: '/mypolicies',
        name: 'MyPolicies',
        component: () => import(/* webpackChunkName: "MyPolicies" */ './mod_policie/MyPolicies.vue'),
        meta: {
            authRequired: true
        }
    },
    {
        path: '/gestion',
        name: 'SegPolicies',
        component: () => import(/* webpackChunkName: "SegPolicies" */ './mod_policie/SegPolicies.vue'),
        meta: {
            authRequired: true
        }
    },
    {
        path: '/insight',
        name: 'Insight',
        component: () => import(/* webpackChunkName: "SegPolicies" */ './mod_policie/Insight.vue'),
        meta: {
            authRequired: true
        }
    },
    {
        path: '/editpolicies',
        name: 'EditPolicies',
        component: () => import(/* webpackChunkName: "SegPolicies" */ './mod_conf/EditPolicies.vue'),
        meta: {
            authRequired: true
        }
    },
    {
        path: '/change',
        name: 'Change',
        component: () => import(/* webpackChunkName: "SegPolicies" */ './mod_conf/Change.vue'),
        meta: {
            authRequired: true
        }
    },
    {
        path: '/vehicle',
        name: 'Vehicle',
        component: () => import(/* webpackChunkName: "SegPolicies" */ './mod_consult/Vehicle.vue'),
        meta: {
            authRequired: true
        }
    },
    {
        path: '/gestionbroker',
        name: 'GestionBroker',
        component: () => import(/* webpackChunkName: "SegPolicies" */ './mod_policie/BrokerPolicies.vue'),
        meta: {
            authRequired: true
        }
    },
    {
        path: '/historical',
        name: 'Historical',
        component: () => import(/* webpackChunkName: "Historical" */ './mod_policie/Historical.vue'),
        meta: {
            authRequired: true
        }
    },

    {
        path: '/quotation1',
        name: 'Quotation1',
        component: () => import(/* webpackChunkName: "Quotation1" */ './components/Quotation1.vue'),
        meta: {
            authRequired: true
        },
        children: [
            {
                path: '',
                component: () => import('./components/quotation1/Steps1.vue'),
                meta: {
                    authRequired: true
                }
            },
            {
                path: '/quotation1/steps2',
                component: () => import('./components/quotation1/Steps2.vue'),
                meta: {
                    authRequired: true
                }
            },
            {
                path: '/quotation1/steps3',
                component: () => import('./components/quotation1/Steps3.vue'),
                meta: {
                    authRequired: true
                }
            },
            {
                path: '/quotation1/steps4',
                component: () => import('./components/quotation1/Steps4.vue'),
                meta: {
                    authRequired: true
                }
            },
            {
                path: '/quotation1/steps5',
                component: () => import('./components/quotation1/Steps5.vue'),
                meta: {
                    authRequired: true
                }
            },
        ],

    },

    {
        path: '/quotation2',
        name: 'Quotation2',
        component: () => import(/* webpackChunkName: "Quotation2" */ './components/Quotation2.vue'),
        meta: {
            authRequired: true
        },
        children: [
            {
                path: '',
                component: () => import('./components/quotation2/Steps1.vue'),
                meta: {
                    authRequired: true
                }
            },
            {
                path: '/quotation2/steps2',
                component: () => import('./components/quotation2/Steps2.vue'),
                meta: {
                    authRequired: true
                }
            },
            {
                path: '/quotation2/steps3',
                component: () => import('./components/quotation2/Steps3.vue'),
                meta: {
                    authRequired: true
                }
            },
            {
                path: '/quotation2/steps4',
                component: () => import('./components/quotation2/Steps4.vue'),
                meta: {
                    authRequired: true
                }
            },
            {
                path: '/quotation2/steps5',
                component: () => import('./components/quotation2/Steps5.vue'),
                meta: {
                    authRequired: true
                }
            },
        ],

    },

    {
        path: '/quotation3',
        name: 'Quotation3',
        component: () => import(/* webpackChunkName: "Quotation3" */ './components/Quotation3.vue'),
        meta: {
            authRequired: true
        },
        children: [
            {
                path: '',
                component: () => import('./components/quotation3/Steps1.vue'),
                meta: {
                    authRequired: true
                }
            },
            {
                path: '/quotation3/steps2',
                component: () => import('./components/quotation3/Steps2.vue'),
                meta: {
                    authRequired: true
                }
            },
            {
                path: '/quotation3/steps3',
                component: () => import('./components/quotation3/Steps3.vue'),
                meta: {
                    authRequired: true
                }
            },
        ],

    },

    {
        path: '/quotation4',
        name: 'Quotation4',
        component: () => import(/* webpackChunkName: "Quotation4" */ './components/Quotation4.vue'),
        meta: {
            authRequired: true
        },
        children: [
            {
                path: '',
                component: () => import('./components/quotation4/Steps1.vue'),
                meta: {
                    authRequired: true
                }
            },
            {
                path: '/quotation4/steps2',
                component: () => import('./components/quotation4/Steps2.vue'),
                meta: {
                    authRequired: true
                }
            },
            {
                path: '/quotation4/steps3',
                component: () => import('./components/quotation4/Steps3.vue'),
                meta: {
                    authRequired: true
                }
            },
            {
                path: '/quotation4/steps4',
                component: () => import('./components/quotation4/Steps4.vue'),
                meta: {
                    authRequired: true
                }
            },
        ],

    },
    {
        path: '/quotation5',
        name: 'Quotation5',
        component: () => import(/* webpackChunkName: "Quotation1" */ './components/Quotation5.vue'),
        meta: {
            authRequired: true
        },
        children: [
            {
                path: '',
                component: () => import('./components/quotation5/Steps1.vue'),
                meta: {
                    authRequired: true
                }
            },
            {
                path: '/quotation5/steps2',
                component: () => import('./components/quotation5/Steps2.vue'),
                meta: {
                    authRequired: true
                }
            },
            {
                path: '/quotation5/steps3',
                component: () => import('./components/quotation5/Steps3.vue'),
                meta: {
                    authRequired: true
                }
            },
            {
                path: '/quotation5/steps4',
                component: () => import('./components/quotation5/Steps4.vue'),
                meta: {
                    authRequired: true
                }
            },
            {
                path: '/quotation5/steps5',
                component: () => import('./components/quotation5/Steps5.vue'),
                meta: {
                    authRequired: true
                }
            },
        ],

    },

    {
        path: '/quotation6',
        name: 'Quotation6',
        component: () => import(/* webpackChunkName: "Quotation1" */ './components/Quotation6.vue'),
        meta: {
            authRequired: true
        },
        children: [
            {
                path: '',
                component: () => import('./components/quotation6/Steps1.vue'),
                meta: {
                    authRequired: true
                }
            },
            {
                path: '/quotation6/steps2',
                component: () => import('./components/quotation1/Steps2.vue'),
                meta: {
                    authRequired: true
                }
            },
            {
                path: '/quotation6/steps3',
                component: () => import('./components/quotation6/Steps3.vue'),
                meta: {
                    authRequired: true
                }
            },
            {
                path: '/quotation6/steps4',
                component: () => import('./components/quotation6/Steps4.vue'),
                meta: {
                    authRequired: true
                }
            },
            {
                path: '/quotation6/steps5',
                component: () => import('./components/quotation6/Steps5.vue'),
                meta: {
                    authRequired: true
                }
            },
        ],

    },

    {
        path: '/quotations1',
        name: 'Quotations1',
        component: () => import(/* webpackChunkName: "Quotations1" */ './components/Quotations1.vue'),
        meta: {
            authRequired: true
        },
        children: [
            {
                path: '',
                component: () => import('./components/quotations1/Steps1.vue'),
                meta: {
                    authRequired: true
                }
            },
            {
                path: '/quotations1/steps2',
                component: () => import('./components/quotations1/Steps2.vue'),
                meta: {
                    authRequired: true
                }
            },
            {
                path: '/quotations1/steps3',
                component: () => import('./components/quotations1/Steps3.vue'),
                meta: {
                    authRequired: true
                }
            },
            {
                path: '/quotations1/steps4',
                component: () => import('./components/quotations1/Steps4.vue'),
                meta: {
                    authRequired: true
                }
            },
            {
                path: '/quotations1/steps5',
                component: () => import('./components/quotations1/Steps5.vue'),
                meta: {
                    authRequired: true
                }
            },
        ],

    },

    {
        path: '/quotations2',
        name: 'Quotations2',
        component: () => import(/* webpackChunkName: "Quotations2" */ './components/Quotations2.vue'),
        meta: {
            authRequired: true
        },
        children: [
            {
                path: '',
                component: () => import('./components/quotations2/Steps1.vue'),
                meta: {
                    authRequired: true
                }
            },
            {
                path: '/quotations2/steps2',
                component: () => import('./components/quotations2/Steps2.vue'),
                meta: {
                    authRequired: true
                }
            },
            {
                path: '/quotations2/steps3',
                component: () => import('./components/quotations2/Steps3.vue'),
                meta: {
                    authRequired: true
                }
            },
            {
                path: '/quotations2/steps4',
                component: () => import('./components/quotations2/Steps4.vue'),
                meta: {
                    authRequired: true
                }
            },
            {
                path: '/quotations2/steps5',
                component: () => import('./components/quotations2/Steps5.vue'),
                meta: {
                    authRequired: true
                }
            },
        ],

    },

    {
        path: '/quotations3',
        name: 'Quotations3',
        component: () => import(/* webpackChunkName: "Quotations3" */ './components/Quotations3.vue'),
        meta: {
            authRequired: true
        },
        children: [
            {
                path: '',
                component: () => import('./components/quotations3/Steps1.vue'),
                meta: {
                    authRequired: true
                }
            },
            {
                path: '/quotations3/steps2',
                component: () => import('./components/quotations3/Steps2.vue'),
                meta: {
                    authRequired: true
                }
            },
            {
                path: '/quotations3/steps3',
                component: () => import('./components/quotations3/Steps3.vue'),
                meta: {
                    authRequired: true
                }
            },
        ],

    },

    {
        path: '/quotations4',
        name: 'Quotations4',
        component: () => import(/* webpackChunkName: "Quotations4" */ './components/Quotations4.vue'),
        meta: {
            authRequired: true
        },
        children: [
            {
                path: '',
                component: () => import('./components/quotations4/Steps1.vue'),
                meta: {
                    authRequired: true
                }
            },
            {
                path: '/quotations4/steps2',
                component: () => import('./components/quotations4/Steps2.vue'),
                meta: {
                    authRequired: true
                }
            },
            {
                path: '/quotations4/steps3',
                component: () => import('./components/quotations4/Steps3.vue'),
                meta: {
                    authRequired: true
                }
            },
            {
                path: '/quotations4/steps4',
                component: () => import('./components/quotations4/Steps4.vue'),
                meta: {
                    authRequired: true
                }
            },
        ],

    },

    {
        path: '/quotations5',
        name: 'Quotations5',
        component: () => import(/* webpackChunkName: "Quotations1" */ './components/Quotations5.vue'),
        meta: {
            authRequired: true
        },
        children: [
            {
                path: '',
                component: () => import('./components/quotations5/Steps1.vue'),
                meta: {
                    authRequired: true
                }
            },
            {
                path: '/quotations5/steps2',
                component: () => import('./components/quotations5/Steps2.vue'),
                meta: {
                    authRequired: true
                }
            },
            {
                path: '/quotations5/steps3',
                component: () => import('./components/quotations5/Steps3.vue'),
                meta: {
                    authRequired: true
                }
            },
            {
                path: '/quotations5/steps4',
                component: () => import('./components/quotations5/Steps4.vue'),
                meta: {
                    authRequired: true
                }
            },
            {
                path: '/quotations5/steps5',
                component: () => import('./components/quotations5/Steps5.vue'),
                meta: {
                    authRequired: true
                }
            },
        ],

    },

    {
        path: '/quotations6',
        name: 'Quotations6',
        component: () => import(/* webpackChunkName: "Quotations1" */ './components/Quotations6.vue'),
        meta: {
            authRequired: true
        },
        children: [
            {
                path: '',
                component: () => import('./components/quotations6/Steps1.vue'),
                meta: {
                    authRequired: true
                }
            },
            {
                path: '/quotations6/steps2',
                component: () => import('./components/quotations6/Steps2.vue'),
                meta: {
                    authRequired: true
                }
            },
            {
                path: '/quotations6/steps3',
                component: () => import('./components/quotations6/Steps3.vue'),
                meta: {
                    authRequired: true
                }
            },
            {
                path: '/quotations6/steps4',
                component: () => import('./components/quotations6/Steps4.vue'),
                meta: {
                    authRequired: true
                }
            },
            {
                path: '/quotations6/steps5',
                component: () => import('./components/quotations6/Steps5.vue'),
                meta: {
                    authRequired: true
                }
            },
        ],

    },



    {
        path: '/evaluation1',
        name: 'Evaluation1',
        component: () => import(/* webpackChunkName: "Evaluation1" */ './mod_quotation/Evaluation1.vue'),
        meta: {
            authRequired: true
        }
    },
    {
        path: '/evaluation2',
        name: 'Evaluation2',
        component: () => import(/* webpackChunkName: "Evaluation2" */ './mod_quotation/Evaluation2.vue'),
        meta: {
            authRequired: true
        }
    },
    {
        path: '/evaluation3',
        name: 'Evaluation3',
        component: () => import(/* webpackChunkName: "Evaluation3" */ './mod_quotation/Evaluation3.vue'),
        meta: {
            authRequired: true
        }
    },
    {
        path: '/evaluation4',
        name: 'Evaluation4',
        component: () => import(/* webpackChunkName: "Evaluation4" */ './mod_quotation/Evaluation4.vue'),
        meta: {
            authRequired: true
        }
    },
    {
        path: '/evaluation5',
        name: 'Evaluation5',
        component: () => import(/* webpackChunkName: "Evaluation1" */ './mod_quotation/Evaluation5.vue'),
        meta: {
            authRequired: true
        }
    },
    {
        path: '/evaluation6',
        name: 'Evaluation6',
        component: () => import(/* webpackChunkName: "Evaluation1" */ './mod_quotation/Evaluation6.vue'),
        meta: {
            authRequired: true
        }
    },

    {
        path: '/payment',
        name: 'Payment',
        component: () => import(/* webpackChunkName: "Payment" */ './mod_payment/Payment.vue'),
        meta: {
            authRequired: true
        },
        children: [
            {
                path: '',
                component: () => import('./components/payment/Informacion.vue'),
                meta: {
                    authRequired: true
                }
            },
            {
                path: '/payment/pago',
                component: () => import('./components/payment/Pago.vue'),
                meta: {
                    authRequired: true
                }
            },
        ],

    },

    {
        path: '/paymentsr',
        name: 'Paymentsr',
        component: () => import(/* webpackChunkName: "Paymentsr" */ './mod_payment/Paymentsr.vue'),
        meta: {
            authRequired: true
        },
        children: [
            {
                path: '',
                component: () => import('./components/paymentsr/Informacion.vue'),
                meta: {
                    authRequired: true
                }
            },
            {
                path: '/paymentsr/pago',
                component: () => import('./components/paymentsr/Pago.vue'),
                meta: {
                    authRequired: true
                }
            },
        ],

    },





    {
        path: '/paymentreview',
        name: 'Paymentreview',
        component: () => import(/* webpackChunkName: "Paymentreview" */ './mod_payment/Paymentreview.vue'),
        meta: {
            authRequired: true
        }
    },
    {
        path: '/mypayments',
        name: 'Mypayments',
        component: () => import(/* webpackChunkName: "Mypayments" */ './mod_payment/Mypayments.vue'),
        meta: {
            authRequired: true
        }
    },


    {
        path: '/profile',
        name: 'Profile',
        component: () => import(/* webpackChunkName: "Profile" */ './pages/Profile.vue'),
        meta: {
            authRequired: true
        }
    },
    {
        path: '/typepolicie',
        name: 'Typepolicie',
        component: () => import(/* webpackChunkName: "Typepolicie" */ './mod_conf/Typepolicie.vue'),
        meta: {
            authRequired: true
        }
    },
    {
        path: '/roles',
        name: 'Roles',
        component: () => import(/* webpackChunkName: "Roles" */ './mod_conf/Roles.vue'),
        meta: {
            authRequired: true
        }
    },
    {
        path: '/tarif',
        name: 'Tarif',
        component: () => import(/* webpackChunkName: "Tarif" */ './mod_conf/Tarif.vue'),
        meta: {
            authRequired: true
        }
    },
    {
        path: '/typecoverage',
        name: 'TypeCoverage',
        component: () => import(/* webpackChunkName: "TypeCoverage" */ './mod_conf/TypeCoverage.vue'),
        meta: {
            authRequired: true
        }
    },
    {
        path: '/vehicles',
        name: 'Vehicles',
        component: () => import(/* webpackChunkName: "Vehicles" */ './mod_conf/Vehicles.vue'),
        meta: {
            authRequired: true
        }
    },

    {
        path: '/claim1',
        name: 'Claim1',
        component: () => import(/* webpackChunkName: "Claim1" */ './mod_claim/Claim1.vue'),
        meta: {
            authRequired: true
        }
    },
    {
        path: '/claim1_for',
        name: 'Claim1_For',
        component: () => import(/* webpackChunkName: "Claim1_For" */ './mod_claim/Claim1_For.vue'),
        meta: {
            authRequired: true
        }
    },
    {
        path: '/claim1_eval',
        name: 'Claim1_Eval',
        component: () => import(/* webpackChunkName: "Claim1_Eval" */ './mod_claim/Claim1_Eval.vue'),
        meta: {
            authRequired: true
        }
    },
    {
        path: '/claim1_adm',
        name: 'Claim1_Adm',
        component: () => import(/* webpackChunkName: "Claim1_Adm" */ './mod_claim/Claim1_Adm.vue'),
        meta: {
            authRequired: true
        }
    },
    {
        path: '/claim1_main',
        name: 'Claim1_Main',
        component: () => import(/* webpackChunkName: "Claim1_Main" */ './mod_claim/Claim1_Main.vue'),
        meta: {
            authRequired: true
        }
    },

    {
        path: '/claim2',
        name: 'Claim2',
        component: () => import(/* webpackChunkName: "Claim2" */ './mod_claim/Claim2.vue'),
        meta: {
            authRequired: true
        }
    },
    {
        path: '/claim2_for',
        name: 'Claim2_For',
        component: () => import(/* webpackChunkName: "Claim2_For" */ './mod_claim/Claim2_For.vue'),
        meta: {
            authRequired: true
        }
    },
    {
        path: '/claim2_eval',
        name: 'Claim2_Eval',
        component: () => import(/* webpackChunkName: "Claim2_Eval" */ './mod_claim/Claim2_Eval.vue'),
        meta: {
            authRequired: true
        }
    },
    {
        path: '/claim2_adm',
        name: 'Claim2_Adm',
        component: () => import(/* webpackChunkName: "Claim2_Adm" */ './mod_claim/Claim2_Adm.vue'),
        meta: {
            authRequired: true
        }
    },
    {
        path: '/claim2_main',
        name: 'Claim2_Main',
        component: () => import(/* webpackChunkName: "Claim2_Main" */ './mod_claim/Claim2_Main.vue'),
        meta: {
            authRequired: true
        }
    },


    {
        path: '/claim3',
        name: 'Claim3',
        component: () => import(/* webpackChunkName: "Claim3" */ './mod_claim/Claim3.vue'),
        meta: {
            authRequired: true
        }
    },
    {
        path: '/claim3_for',
        name: 'Claim3_For',
        component: () => import(/* webpackChunkName: "Claim3_For" */ './mod_claim/Claim3_For.vue'),
        meta: {
            authRequired: true
        }
    },
    {
        path: '/claim3_eval',
        name: 'Claim3_Eval',
        component: () => import(/* webpackChunkName: "Claim3_Eval" */ './mod_claim/Claim3_Eval.vue'),
        meta: {
            authRequired: true
        }
    },
    {
        path: '/claim3_adm',
        name: 'Claim3_Adm',
        component: () => import(/* webpackChunkName: "Claim3_Adm" */ './mod_claim/Claim3_Adm.vue'),
        meta: {
            authRequired: true
        }
    },
    {
        path: '/claim3_main',
        name: 'Claim3_Main',
        component: () => import(/* webpackChunkName: "Claim3_Main" */ './mod_claim/Claim3_Main.vue'),
        meta: {
            authRequired: true
        }
    },

    {
        path: '/claim4',
        name: 'Claim4',
        component: () => import(/* webpackChunkName: "Claim4" */ './mod_claim/Claim4.vue'),
        meta: {
            authRequired: true
        }
    },
    {
        path: '/claim4_for',
        name: 'Claim4_For',
        component: () => import(/* webpackChunkName: "Claim4_For" */ './mod_claim/Claim4_For.vue'),
        meta: {
            authRequired: true
        }
    },
    {
        path: '/claim4_eval',
        name: 'Claim4_Eval',
        component: () => import(/* webpackChunkName: "Claim4_Eval" */ './mod_claim/Claim4_Eval.vue'),
        meta: {
            authRequired: true
        }
    },
    {
        path: '/claim4_adm',
        name: 'Claim4_Adm',
        component: () => import(/* webpackChunkName: "Claim4_Adm" */ './mod_claim/Claim4_Adm.vue'),
        meta: {
            authRequired: true
        }
    },
    {
        path: '/claim4_main',
        name: 'Claim4_Main',
        component: () => import(/* webpackChunkName: "Claim4_Main" */ './mod_claim/Claim4_Main.vue'),
        meta: {
            authRequired: true
        }
    },

    {
        path: '/complaint',
        name: 'Complaint',
        component: () => import(/* webpackChunkName: "Complaint" */ './pages/Complaint.vue'),
        meta: {
            authRequired: true
        }
    },

    

    {
        path: '/complains',
        name: 'Complains',
        component: () => import(/* webpackChunkName: "Complains" */ './pages/Complains.vue'),
        meta: {
            authRequired: true
        }
    },


    {
        path: '/report1',
        name: 'Report1',
        component: () => import(/* webpackChunkName: "Policies" */ './mod_reports/Policies.vue'),
        meta: {
            authRequired: true
        }
    },
    {
        path: '/report2',
        name: 'Report2',
        component: () => import(/* webpackChunkName: "Quotations" */ './mod_reports/Quotations.vue'),
        meta: {
            authRequired: true
        }
    },
    {
        path: '/report3',
        name: 'Report3',
        component: () => import(/* webpackChunkName: "Claims" */ './mod_reports/Claims.vue'),
        meta: {
            authRequired: true
        }
    },
    {
        path: '/report4',
        name: 'Report4',
        component: () => import(/* webpackChunkName: "Complains" */ './mod_reports/Complains.vue'),
        meta: {
            authRequired: true
        }
    },
    {
        path: '/report5',
        name: 'Report5',
        component: () => import(/* webpackChunkName: "Payments" */ './mod_reports/Payments.vue'),
        meta: {
            authRequired: true
        }
    },
    {
        path: '/report6',
        name: 'Report6',
        component: () => import(/* webpackChunkName: "Quotas" */ './mod_reports/Quotas.vue'),
        meta: {
            authRequired: true
        }
    },
    {
        path: '/report7',
        name: 'Report7',
        component: () => import(/* webpackChunkName: "Accounting" */ './mod_reports/Accounting.vue'),
        meta: {
            authRequired: true
        }
    },
    {
        path: '/report8',
        name: 'Report8',
        component: () => import(/* webpackChunkName: "Accounting" */ './mod_reports/Consolidated.vue'),
        meta: {
            authRequired: true
        }
    },
    {
        path: '/report9',
        name: 'Report9',
        component: () => import(/* webpackChunkName: "Accounting" */ './mod_reports/Consolidated2.vue'),
        meta: {
            authRequired: true
        }
    },
    {
        path: '/report10',
        name: 'Report10',
        component: () => import(/* webpackChunkName: "Accounting" */ './mod_reports/Change.vue'),
        meta: {
            authRequired: true
        }
    },
    {
        path: '/report11',
        name: 'Report11',
        component: () => import(/* webpackChunkName: "Accounting" */ './mod_reports/Renovacion.vue'),
        meta: {
            authRequired: true
        }
    },
    {
        path: '/report12',
        name: 'Report12',
        component: () => import(/* webpackChunkName: "Accounting" */ './mod_reports/Sales.vue'),
        meta: {
            authRequired: true
        }
    },
    {
        path: '/report13',
        name: 'Report13',
        component: () => import(/* webpackChunkName: "Accounting" */ './mod_reports/Consumed.vue'),
        meta: {
            authRequired: true
        }
    },
    {
        path: '/report14',
        name: 'Report14',
        component: () => import(/* webpackChunkName: "Accounting" */ './mod_reports/Sales1.vue'),
        meta: {
            authRequired: true
        }
    },
    {
        path: '/report15',
        name: 'Report15',
        component: () => import(/* webpackChunkName: "Accounting" */ './mod_reports/Renewals.vue'),
        meta: {
            authRequired: true
        }
    },
    {
        path: '/report16',
        name: 'Report16',
        component: () => import(/* webpackChunkName: "Accounting" */ './mod_reports/Income.vue'),
        meta: {
            authRequired: true
        }
    },
    {
        path: '/blank',
        name: 'Blank',
        component: () => import(/* webpackChunkName: "Blank" */ './pages/Blank.vue'),
    }
];

const router = createRouter({
    history: createWebHashHistory(),
    routes,
});






export default router;
