import QR from "qr-encode";
import url from "../_URL";

function certif(pdf,data,mensaje) {
    var logo = "images/logo.jpg";
    var dataURI = QR(url.val+data.id, { type: 6, size: 5, level: "Q" ,
    image: "https://upload.wikimedia.org/wikipedia/commons/5/51/Facebook_f_logo_%282019%29.svg",});
    var img = new Image();
    img.src = dataURI;
    var qr = img.src;

    pdf.addImage(qr, 'JPEG', 165, 20, 27, 27);
    pdf.setFontSize(6);
    pdf.setFont("italic","bold");
    pdf.text("Scan QR code for VALIDITY", 165, 18);

    pdf.addImage(logo, 'JPEG', 10, 20, 60, 15);
    pdf.setFontSize(12);
    pdf.setFont("italic", "bold");
    pdf.text("SaBeé Insurance", 75, 25);
    pdf.setFontSize(8);
    pdf.setFont("italic","normal");
    pdf.text("Do it Secure, Do it SaBee", 75, 28);
    pdf.text("World Trade Center, Piscadera Bay Z/N, Willemstad – Curaçao", 75, 31);
    pdf.text("Phone +59996761002 - +59996905811", 75, 34);
    pdf.text("Email: sales@sabeeinsurance.com", 75, 37);
    pdf.text(100, 139, 'Sabeé Insurance- Registration Number 102716', 'center');
    pdf.setFont("italic","normal");
    pdf.text(100, 142, 'Website: www.sabeeinsurance.com', 'center');

    pdf.setFontSize(12);
    pdf.setFont("italic","bold");
    pdf.text(100, 50, "POLICY: "+data.codigo, 'center');

    pdf.setFontSize(10);
    pdf.rect(10, 10, 190, 125); 

    pdf.setFont("italic","bold");
    pdf.text("Doc Id:", 15, 58);
    pdf.text("Policy Holder:", 60, 58);
    pdf.text("Client Nr:", 135, 58);
    pdf.text("Policy:", 15, 65);
    pdf.text("Duration:", 60, 65);
    pdf.text("Period:", 135, 65);

    pdf.setFont("italic","normal");
    pdf.text(data.salud.code, 30, 58);        
    pdf.text(data.salud.nombre, 90, 58);
    pdf.text('# '+data.usuario.toString().padStart(7,'0'), 155, 58);
    pdf.text(data.salud.tipopoliza_name, 30, 65);
    pdf.text(data.duracion_name, 80, 65);
    pdf.text(data.activacion+' - '+data.vence, 155, 65);

    pdf.setFont("italic","bold");
    pdf.text(100, 73, 'Health Insurance Benefits (amount coverage based on policy type)', 'center');
    
    pdf.setFont("italic","normal");
    pdf.setFontSize(9);
    pdf.text('A. HOSPITALIZATION:', 20, 80);        
    pdf.text('Daily Room and Board', 55, 80);        
    pdf.text('Miscellaneous Hospital Service ', 55, 84);        
    pdf.text('Emergency Accident and Out -Patient treatment ', 55, 88);        
    pdf.text('Surgical expenses    Max. of cur. Surgical tariff', 55, 92);        
 
    pdf.text('B. MATERNITY:', 20, 96);        
    pdf.text('Max. for any one pregnancy', 45, 96);        
    pdf.text('Childbirth or miscarriage       Consult Naf:  35,00', 45, 100); 

    pdf.text('C. SPECIAL MEDICAL EXPENSES:', 20, 104);        
    pdf.text('Doctor’s Visit   a) Home Naf: 35,00   b) Office Naf:35,00', 70, 104);     
    pdf.text('Specialist’s Consultation', 70, 108);  
    pdf.text('Prescribed Medicines', 70, 112);  
    pdf.text('Diagnostics, X-Ray & Laboratory', 70, 116);  
    pdf.text('Local Ambulance', 70, 120);     

    pdf.text(100, 129, 'COVERAGE SUBJECT TO FULL PAYMENT OF THE PREMIUM', 'center');
    pdf.text(100, 133, 'The client has accepted policy conditions. See terms & conditions.', 'center');
    pdf.text(mensaje, 70, 130);
}
export default certif;