import { createStore } from 'vuex';
import url from "../service/_URL";
import API from "../service/ApiConsulta";
import { useI18nPlugin } from '@unify/vuex-i18n';
import router from '../router.js';

export default createStore({
  state: {
    loading: false,
    authenticated: false,
    clave: null,
    valido: false,
    url: null,
    id: null,
    foto: 'foto.jpg',
    fecha: null,
    fechaini: null,
    correo: null,
    usuario: null,
    nombre: null,
    rol: 0,
    moneda: null,
    verificacion: false,
    estatus: false,
    mobile: false,
    error: null,
    admin: false,
    menu: {
      es: {
        basico: [
          {label: 'Inicio', icon:'pi pi-home', to: '/'},
          {label: 'Servicios', icon:'pi pi-thumbs-up', to: '/services'},
          {label: 'Empresa', icon:'pi pi-globe', to: '/company'},
          {label: 'Contacto', icon:'pi pi-comments', to: '/contact'}
        ],
        principal: [],
        admin: [],
        externo: []
      },
      en: {
        basico: [],
        principal: [],
        admin: [],
        externo: []
      }
    },
    tras: [],
    void: false,
    void_data: null,
    paginas: [],
    currency: null,
    year: null,
    years: [],
    years2: [],
    mes: null,
    meses: [
      {name:'January', nombre:'Enero', code:1},
      {name:'February', nombre:'Febrero', code:2},
      {name:'March', nombre:'Marzo', code:3},
      {name:'April', nombre:'Abril', code:4},
      {name:'May', nombre:'Mayo', code:5},
      {name:'June', nombre:'Junio', code:6},
      {name:'July', nombre:'Julio', code:7},
      {name:'August', nombre:'Agosto', code:8},
      {name:'September', nombre:'Septiembre', code:9},
      {name:'Octuber', nombre:'Octubre', code:10},
      {name:'November', nombre:'Noviembre', code:11},
      {name:'December', nombre:'Diciembre', code:12}
    ]
  },
  mutations: {
    Loading(){
      this.state.loading = !this.state.loading;	
    },
    Estatus(){
      this.state.url = url.doc;	
      const Consulta = new API();
      Consulta.Estatus().then(response => {
        //this.state.error = response;
        this.state.estatus = response.estatus;
        this.state.fecha = response.fecha;
        this.state.fechaini = response.fecha.replace(/^(\d{4})-(\d{2})-(\d{2})$/g,'$3/$2/$1');
        this.state.currency = response.moneda;
        this.state.mes = this.state.meses.filter(val => val.code == response.mes)[0];
        for(var k = response.year; k >= 2022; k--) {
          this.state.years.push({
            nombre: k, 
            code: k
          });
        }
        for(var z = parseInt(response.year)+2; z >= 2022; z--) {
          this.state.years2.push({
            nombre: z, 
            code: z
          });
        }
        this.state.year = {nombre: response.year, code: response.year};
      });   
    },
    Login(state,valores){
      this.state.authenticated = true;
      localStorage.setItem('Token', valores.token);
      this.state.id = valores.id;
      this.state.moneda = valores.moneda;
      if(valores.foto){this.state.foto = valores.foto;}
      this.state.usuario = valores.nombre;   
      this.state.nombre = valores.nombre;   
      this.state.correo = valores.correo;
      this.state.valido = valores.valido;
      this.state.admin = valores.admin;
      this.state.menu.es.principal = valores.menu
      this.state.menu.es.admin = valores.menuadmin;
      this.state.menu.es.externo = valores.menuexterno;
      this.state.currency = valores.moneda.codigo;
      //this.state.error = valores.menuexterno;
    },
    Perfil(state,valores){
      this.state.usuario = valores.nombre;   
      this.state.nombre = valores.nombre;   
      this.state.correo = valores.correo;
    },
    Menu(){
      const i18n = useI18nPlugin();
      this.state.menu.en = {
        basico: [],
        principal: [],
        admin: [],
        externo: [],

      };
      i18n.set('en');
      this.state.menu.es.basico.forEach(element => {
        this.state.menu.en.basico.push({
          label: i18n.translate(element.label),
          icon: element.icon,
          to: element.to
        });
      });
      this.state.paginas = [];
      this.state.menu.es.externo.forEach(element => {
          this.state.paginas.push(element.to);
      });  
      this.state.menu.es.principal.forEach(element => {
        this.state.tras= [];
        element.items.forEach(element => {
          this.state.tras.push({
            label: i18n.translate(element.label),
            icon: element.icon,
            to: element.to
          });
          this.state.paginas.push(element.to);
        }); 
        this.state.menu.en.principal.push({
          modulo: element.modulo,
          label: i18n.translate(element.label),
          icon: element.icon,
          items: this.state.tras
        });
      });  

      if (this.state.admin){
        this.state.menu.es.admin.forEach(result => {
          this.state.menu.en.admin.push({
            label: i18n.translate(result.label),
            icon: result.icon,
            to: result.to
          });
          this.state.paginas.push(result.to);
        });
      }  

      this.state.menu.es.externo.forEach(result => {
        this.state.menu.en.externo.push({
          label: i18n.translate(result.label),
          icon: result.icon,
          to: result.to
        });
      });
      
      //this.state.menu.es.externo = [];
      //this.state.menu.en.externo = [];

      const array1 = this.state.menu.es.externo.concat(this.state.menu.es.principal);
      const array2 = this.state.menu.en.externo.concat(this.state.menu.en.principal);
      this.state.menu.es.principal = array1;
      this.state.menu.en.principal = array2;

      if (localStorage.getItem('leng')){
        i18n.set(localStorage.getItem('leng'));  
      }


    },
    Verificado(state,valor){
      this.state.verificacion = valor.estado;
    },
    Validar(state,pagina){
      this.state.insert = false;
      this.state.edit = false;
      this.state.delete = false;
      if(!this.state.paginas.includes(pagina.path)){
        router.push({ path: '/' });
      } 
    },
    Mobile(){
      if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
        && screen.width<1024) {
        this.state.mobile = true;
      } else {
        this.state.mobile = false;
      }    
    },
    Logout(){
      const Consulta = new API('Seguridad');
      Consulta.Ini('Exit');
      localStorage.removeItem('Token');
      this.state.authenticated = false;
      this.state.usuario = null;   
      this.state.correo = null;
      this.state.valido = false;
      this.state.admin = false;
      this.state.paginas = [];
    }
  },
  actions: {
  },
  modules: {
  }
})
