import QR from "qr-encode";
import url from "../_URL";

function certif(pdf,data,mensaje) {
    var logo = "images/logo.jpg";
    var dataURI = QR(url.val+data.id, { type: 6, size: 5, level: "Q" ,
    image: "https://upload.wikimedia.org/wikipedia/commons/5/51/Facebook_f_logo_%282019%29.svg",});
    var img = new Image();
    img.src = dataURI;
    var qr = img.src;

    pdf.addImage(qr, 'JPEG', 10, 10, 40, 40);
    pdf.setFontSize(9);
    pdf.setFont("italic","bold");
    pdf.text("Scan QR code for VALIDITY", 10, 8);

    if(data.insight_id){
        pdf.text("Scan QR code for Belastingkaart", 150, 10);
        pdf.text("and police search", 160, 13);
        //pdf.text(qrin, 160, 15);
        pdf.addImage('api/qr_insight/'+data.insight_id+'.png', 'PNG', 157, 15, 30, 30);
    }

    pdf.addImage(logo, 'JPEG', 65, 10, 72, 20);
    pdf.setFontSize(12);
    pdf.setFont("italic", "bold");
    pdf.text("SaBeé Insurance", 65, 35);
    pdf.setFontSize(8);
    pdf.setFont("italic","normal");
    pdf.text("Do it Secure, Do it SaBee", 65, 38);
    pdf.text("World Trade Center, Piscadera Bay Z/N, Willemstad – Curaçao", 65, 41);
    pdf.text("Phone +59996761002 - +59996905811", 65, 44);
    pdf.text("Email: sales@sabeeinsurance.com", 65, 47);
    pdf.text(100, 139, 'Sabeé Insurance- Registration Number 102716', 'center');
    pdf.setFont("italic","normal");
    pdf.text(100, 142, 'Website: www.sabeeinsurance.com', 'center');

    pdf.setFontSize(12);
    pdf.setFont("italic","bold");
    pdf.text(100, 56, "POLICY: "+data.codigo, 'center');

    pdf.setFontSize(10);
    pdf.rect(10, 60, 190, 75); 

    pdf.setFont("italic","normal");
    pdf.text("Doc Id:", 15, 70);
    pdf.rect(33, 65, 35, 7); 
    pdf.text("Policy Holder:", 70, 70);
    pdf.rect(95, 65, 100, 7); 
    pdf.text("Client Nr:", 15, 80);
    pdf.rect(33, 75, 35, 7); 
    pdf.text("Policy:", 70, 80);
    pdf.rect(95, 75, 100, 7); 
    pdf.text("Duration:", 15, 90);
    pdf.rect(33, 85, 35, 7); 
    pdf.text("Period:", 70, 90);
    pdf.rect(95, 85, 100, 7); 

    pdf.setFontSize(8);
    pdf.text(100, 130, "Client has accepted policy conditions.", 'center');
    pdf.text(100, 133, "See terms & conditions", 'center');
    pdf.setFontSize(10);
    pdf.text(mensaje, 70, 127);
    pdf.setFontSize(8);

    pdf.setFont("italic","bold");
    pdf.text(data.vehiculo.code, 35, 70);        
    pdf.text(data.vehiculo.nombre, 100, 70);
    pdf.text('# '+data.usuario.toString().padStart(7,'0'), 35, 80);
    pdf.text(data.vehiculo.poliza_name, 100, 80);
    pdf.text(data.duracion_name, 35, 90);
    pdf.text(data.activacion+' - '+data.vence, 100, 90);
    
    pdf.setFontSize(10);
    pdf.setFont("italic","normal");
    pdf.text("Plate Nr:", 15, 100);
    pdf.rect(33, 95, 70, 7); 
    pdf.text("Chassis :", 105, 100);
    pdf.rect(125, 95, 70, 7); 
    pdf.text("Brand:", 15, 110);
    pdf.rect(33, 105, 45, 7); 
    pdf.text("Model:", 80, 110);
    pdf.rect(95, 105, 45, 7); 
    pdf.text("Year:", 145, 110);
    pdf.rect(155, 105, 40, 7); 
    pdf.setFont("italic","bold");
    pdf.text(data.vehiculo.veh_placa, 35, 100);        
    pdf.text(data.vehiculo.veh_chasis, 130, 100);
    pdf.text(data.vehiculo.marca, 35, 110);        
    pdf.text(data.vehiculo.modelo, 100, 110);
    pdf.text(''+data.vehiculo.veh_ano, 160, 110);

    pdf.text("What to do in case of accident? Call 199 (CRS)", 15, 117);
    pdf.text("Claim Reminder: Annual Premiums must be paid in full for your claims to be processed", 15, 122);

}
export default certif;